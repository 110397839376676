import React from "react";
import SearchResultMapPage from "../../../templates/search-results-map-template";

const SearchResultResiLettings = (props) => {

    const fullpath = props.location.pathname
    const location = props.location
    const fullpathone = props.location

    return (
        <React.Fragment>
            <SearchResultMapPage 
                locationname="/property-map/to-rent/" 
                pcategorytype="residential" 
                ptype="lettings" 
                ptypetag="to-rent" 
                pstatustype= {['to-let','let-agreed','let']}
                fullpathone={fullpathone} 
                fullpathname={fullpath}
                {...props}
                psearch="Lettings"
                location={location}
            />
        </React.Fragment>
    );
};

export default SearchResultResiLettings;
